import React from "react";

const Comment = ({ content, author, datetime }) => {
  function createMarkup() {
    console.log("ORIG CONTENT: ", content);
    let indexOfTilde = content.indexOf("~");
    console.log("TILDE: ", indexOfTilde);
    let indexOfSquiggle = content.indexOf("§");
    console.log("SQuIGGLE: ", indexOfSquiggle);
    let newContent = content;
    if (indexOfTilde !== -1 && indexOfSquiggle !== -1) {
      newContent = content.split("");
      newContent =
        content.split("").splice(0, indexOfTilde).join("") +
        content.split("").splice(indexOfTilde, indexOfSquiggle).join("");
    }
    console.log("CONTENT: ", newContent);

    return { __html: newContent };
  }
  return (
    <div className="bg-blue-50 py-4 px-6 mt-2 rounded-lg">
      <div dangerouslySetInnerHTML={createMarkup()}></div>
      <p className="text-sm text-gray-500 text-right italic">{datetime}</p>
    </div>
  );
};

export default Comment;
