import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import WithUser from "./User/User";
import GuardedRoute from "./components/GuardedRoute/GuardedRoute";
import LoginPage from "./Pages/LoginPage/LoginPage";
import UsersPage from "./Pages/UsersPage/UsersPage";
import ProjectPage from "./Pages/ProjectPage/ProjectPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import FeedbackForm from "./Pages/FeedbackForm/FeedbackForm";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute path="/feedback">
            <FeedbackForm />
          </PrivateRoute>
          <PrivateRoute path="/projects/:id">
            <ProjectPage />
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <Dashboard />
          </PrivateRoute>
          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
