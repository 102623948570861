import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { createNewTicket } from "../../Utils/Utils";
import { useAppState } from "../../state";

const schema = yup.object().shape({
  description: yup.string().required("Required").max(300),
  title: yup.string().required("Required").max(150),
  type: yup.string().required("Required"),
  priority: yup.string().required("Required"),
  context: yup.string().required("Required"),
  location: yup.string(),
  link: yup.string(),
});

const NewTicketForm = ({ taskListId, setDisplaySidebar }) => {
  const { user } = useAppState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    // createNewTicket(taskListId, data);
    console.log("USER CREATING TICKET:", user);
    toast.promise(createNewTicket(taskListId, data, user.email), {
      loading: "Creating ticket...",
      success: () => {
        setDisplaySidebar(false);
        return <b>Ticket created</b>;
      },
      error: (err) => `${err}`,
    });
  };
  return (
    <div className="px-4">
      <Toaster />
      <div className="flex justify-between items-center mb-8">
        <h2 class="text-lg font-semibold text-gray-800 capitalize dark:text-white">
          New ticket
        </h2>
        <button
          className="bg-red-500 text-white px-4 py-2 rounded-md cursor-pointer"
          onClick={() => setDisplaySidebar(false)}
        >
          Cancel
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-8">
          <label class="text-gray-700 dark:text-gray-200" for="username">
            Title
          </label>
          <input
            {...register("title")}
            className="block w-full px-2 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          />
          <p>{errors.title?.message}</p>
        </div>

        <div className="mb-8">
          <label class="text-gray-700 dark:text-gray-200" for="username">
            Brief description
          </label>
          <textarea
            {...register("description")}
            className="block w-full px-2 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          />
          <p>{errors.description?.message}</p>
        </div>

        <div className="mb-4">
          <label class="text-gray-700 dark:text-gray-200" for="username">
            Link (Vidyard, etc)
          </label>
          <input
            placeholder=""
            type="text"
            {...register("link")}
            className="block w-full px-2 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          />
          <p>{errors.link?.message}</p>
        </div>

        <div className="mb-4">
          <label class="text-gray-700 dark:text-gray-200" for="username">
            Location
          </label>
          <input
            placeholder="E.g 'In the navigation bar on the blog listing page'"
            type="text"
            {...register("location")}
            className="block w-full px-2 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          />
          <p>{errors.location?.message}</p>
        </div>

        <div className="mb-4">
          <label class="text-gray-700 dark:text-gray-200" for="username">
            Type
          </label>
          <select
            className="block w-full px-2 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            {...register("type")}
          >
            <option value="">Select a type...</option>
            <option value="225018">Bug</option>
            <option value="225020">New feature</option>
          </select>
          <p>{errors.type?.message}</p>
        </div>

        <div className="mb-4">
          <label class="text-gray-700 dark:text-gray-200" for="username">
            Priority
          </label>
          <select
            className="block w-full px-2 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            {...register("priority")}
          >
            <option value="">Select a priority...</option>
            <option value="low">low</option>
            <option value="medium">medium</option>
            <option value="high">high</option>
          </select>
          <p>{errors.priority?.message}</p>
        </div>

        <div className="mb-4">
          <label class="text-gray-700 dark:text-gray-200" for="username">
            Context
          </label>
          <select
            className="block w-full px-2 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            {...register("context")}
          >
            <option value="">Select a context...</option>
            <option value="Mobile">Mobile</option>
            <option value="Desktop">Desktop</option>
            <option value="Both">Both</option>
            <option value="NA">Not applicable</option>
          </select>
          <p>{errors.context?.message}</p>
        </div>

        <input
          className="bg-green-400 mt-8 text-white px-4 py-2 rounded-md cursor-pointer"
          type="submit"
        />
      </form>
    </div>
  );
};

export default NewTicketForm;
