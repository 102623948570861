/* eslint-disable no-bitwise */
import axios from "axios";

export const uuid = () => {
  let dt = new Date().getTime();

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export default uuid;

export const hasPublicTag = (proj) => {
  let bool = false;
  proj.tags.forEach((tag) => {
    if (tag.name == "public") {
      bool = true;
    }
  });
  return bool;
};

export const createNewTicket = (id, data, email) => {
  console.log("CreateNewTicket:");
  console.log("ID: ", id);
  console.log("DATA: ", data);
  let description = `**DETAILS:**
  ${data.description}
  💻 **Context**: ${data.context}
  📍 **Location**: ${data.location}
  🔗 **Link**: [${data.link}](${data.link})
  📝 **Logged by**: ${email}`;
  let reqBody = {
    "todo-item": {
      "use-defaults": false,
      completed: false,
      content: data.title,
      tasklistId: id,
      "creator-id": 472751,
      notify: false,
      "responsible-party-id": "472751",
      // "start-date": "20210811",
      // "due-date": "20210818",
      description: description,
      priority: data.priority,
      // progress: 10,
      // parentTaskId: 0,
      tagIds: data.type,
      "everyone-must-do": false,
      predecessors: [],
      reminders: [],
      // columnId: 0,
      // commentFollowerIds: "3",
      // changeFollowerIds: "3",
      // "grant-access-to": "4",
      private: false,
      customFields: [],
      // "estimated-minutes": 150,
      // pendingFileAttachments: [],
      // updateFiles: true,
      // attachments: "",
      // removeOtherFiles: true,
      // attachmentsCategoryIds: "",
      // pendingFileAttachmentsCategoryIds: "",
      // repeatOptions: {
      //   selecteddays: "",
      //   repeatEndDate: "noEndDate",
      //   repeatsFreq: "norepeat",
      //   monthlyRepeatType: "monthDay",
      // },
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(
        // `http://localhost:5001/project-dashboard-b6bac/us-central1/widgets/tickets/add`,
        `https://us-central1-project-dashboard-b6bac.cloudfunctions.net/widgets/tickets/add`,
        reqBody
      )
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const createNewComment = (id, body, userEmail) => {
  let newBody = `${body}
  
  ~by ${userEmail}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        // `http://localhost:5001/project-dashboard-b6bac/us-central1/widgets/comments/task/${id}`,
        `https://us-central1-project-dashboard-b6bac.cloudfunctions.net/widgets/comments/task/${id}`,
        {
          comment: {
            body: newBody,
            notify: true,
            isprivate: false,
          },
        }
      )
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
};
