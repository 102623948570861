import React from "react";

const TableCell = ({ data, textColour, dot, tag }) => {
  return (
    <td class=" text-gray-600 border-gray-100 text-sm">
      <span
        style={
          textColour && tag
            ? { color: textColour, backgroundColor: textColour + 20 }
            : null
        }
        className="px-2 py-1 rounded-lg flex items-center w-max"
      >
        {dot && (
          <span
            style={{ backgroundColor: textColour }}
            className="p-1 rounded-full mr-2"
          ></span>
        )}
        {data}
      </span>
    </td>
  );
};

export default TableCell;
