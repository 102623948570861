import React from "react";
import { Link } from "react-router-dom";

const FeedbackButton = () => {
  return (
    <div className="absolute left-24 bottom-0 bg-green-400 p-2 px-4 cursor-pointer rounded-t-lg text-white">
      <Link to={"/feedback"}>Give feedback</Link>
    </div>
  );
};

export default FeedbackButton;
