import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserProvider } from "./UserContext";
import { withFirebase } from "../Firebase";

const WithUser = withFirebase(({ children, firebase }) => {
  const [userUid, setUserUid] = useState(null);
  const [userAvatar, setUserAvatar] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        setUserUid(user.uid);
        setUserAvatar(user.photoURL);
        setUserEmail(user.email);
        // history.push('/');
      } else {
        setUserUid(null);
      }
    });
  });
  return (
    <UserProvider
      value={{ uid: userUid, avatar: userAvatar, email: userEmail }}
      // value={{ uid: 123, avatar: "avatar", email: "email" }}
    >
      {children}
    </UserProvider>
  );
});

export default WithUser;
