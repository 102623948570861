import React from "react";

const TableHead = ({ title, width, action }) => {
  return (
    <th
      class={`p-4 ${width} text-left border-b text-gray-600 cursor-pointer hover:bg-gray-50`}
      onClick={action}
    >
      {title}
    </th>
  );
};

export default TableHead;
