import React, { createContext, useContext, useReducer, useState } from "react";
// import { RecordingRules, RoomType } from '../types';
// import { TwilioError } from 'twilio-video';
// import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
// import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from "./useFirebaseAuth/useFirebaseAuth";
// import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
// import { User } from "firebase";

// export interface StateContextType {
//   error: TwilioError | Error | null;
//   setError(error: TwilioError | Error | null): void;
//   getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;
//   user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
//   signIn?(passcode?: string): Promise<void>;
//   signOut?(): Promise<void>;
//   isAuthReady?: boolean;
//   isFetching: boolean;
//   activeSinkId: string;
//   setActiveSinkId(sinkId: string): void;
//   settings: Settings;
//   dispatchSetting: React.Dispatch<SettingsAction>;
//   roomType?: RoomType;
//   updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
// }

export const StateContext = createContext(null);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider({ children }) {
  const [isFetching, setIsFetching] = useState(false);
  const [showCommentsPanel, setShowCommentsPanel] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  let contextValue = {
    isFetching,
    showCommentsPanel,
    setShowCommentsPanel,
    selectedTask,
    setSelectedTask,
  };

  contextValue = {
    ...contextValue,
    ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
