/* eslint-disable */

import React from "react";

const Sidebar = ({ children, display }) => {
  return (
    <div
      class={`${
        display ? "w-6/12" : "w-0 hidden"
      } flex transition-all duration-200 flex-col h-screen z-10 overflow-scroll px-4 py-8 bg-white top-0 right-0 border-r shadow-xl absolute dark:bg-gray-800 dark:border-gray-600`}
    >
      {children}
    </div>
  );
};

export default Sidebar;
