import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Firebase, { FirebaseContext } from "./Firebase";
import "./index.css";
import AppStateProvider from "./state";

ReactDOM.render(
  <React.StrictMode>
    {/* <FirebaseContext.Provider value={new Firebase()}> */}
    <AppStateProvider>
      <App />
    </AppStateProvider>
    {/* </FirebaseContext.Provider> */}
  </React.StrictMode>,
  document.getElementById("root")
);
