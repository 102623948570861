/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useEffect, useState } from "react";
import { useAppState } from "../../state";
import toast, { Toaster } from "react-hot-toast";
import Page from "../../components/Page/Page";
import { withFirebase } from "../../Firebase";
import logo from "../../assets/5px.png";
import { useLocation, useHistory } from "react-router-dom";
// import UserContext from "../../User/UserContext";
// import { useHistory } from "react-router-dom";

const LoginPage = withFirebase(({ firebase }) => {
  const { signIn, user, isAuthReady } = useAppState();
  const [authError, setAuthError] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const googleLoginClick = () => {
    setAuthError(null);
    signIn?.()
      .then(() => {
        history.replace(location?.state?.from || { pathname: "/" });
      })
      .catch((err) => setAuthError(err));

    // toast.promise(firebase.signInWithGoogle(), {
    //   loading: "Signing in...",
    //   success: <b>Welcome!</b>,
    //   error: (err) => `${err}`,
    // });
  };
  // const user = useContext(UserContext);
  // const history = useHistory();

  // useEffect(() => {
  //   if (user.uid) {
  //     history.push("/");
  //   }
  // }, [JSON.stringify(user)]);

  return (
    <Page>
      <Toaster />

      <section class="flex flex-col items-center h-screen md:flex-row">
        <div class="container mx-auto">
          <div class="flex justify-center px-2 py-6 ">
            <div class="flex w-full rounded-lg xl:w-3/4 lg:w-11/12 lg:shadow-xl ">
              <div class="relative hidden w-full h-auto bg-white bg-cover border-r rounded-l-lg lg:block lg:w-6/12">
                <div class="relative z-10 m-12 text-left ">
                  <a class="flex items-center w-32 mb-4 font-medium text-blueGray-900 title-font md:mb-10">
                    <div class="w-2 h-2 p-2 mr-2 rounded-full bg-gradient-to-tr from-blue-300 to-blue-600"></div>
                    <h2 class="text-lg font-bold tracking-tighter text-black uppercase transition duration-500 ease-in-out transform hover:text-lightBlack-500 dark:text-lightBlue-400">
                      {" "}
                      5px{" "}
                    </h2>
                  </a>
                  <h2 class="mt-12 mb-2 text-2xl font-semibold tracking-tighter text-black sm:text-3xl title-font">
                    {" "}
                    Sign in.{" "}
                  </h2>
                  <div class="w-full mt-16 mb-8 text-base leading-relaxed text-blueGray-900 sm:md:w-3/3 lg:text-1xl ">
                    {" "}
                    Welcome! Please note that at this time, we only support
                    login through Google. Please click on the button in the next
                    pane to sign in.{" "}
                  </div>
                </div>
              </div>
              <div class="w-full px-8 py-24 bg-white rounded-lg border-blueGray-100 lg:w-8/12 lg:px-24 lg:py-4 lg:rounded-l-none s">
                <div class="relative z-10 text-left flex flex-col items-center justify-center h-full">
                  <img alt="logo" src={logo} className="w-32 mb-16" />
                  <button
                    type="button"
                    className="flex items-center justify-center text-gray-600 rounded-lg border border-gray-200 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                    onClick={googleLoginClick}
                  >
                    <div className="px-4 py-3">
                      <svg className="w-6 h-6" viewBox="0 0 40 40">
                        <path
                          d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
                          fill="#FFC107"
                        />
                        <path
                          d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z"
                          fill="#FF3D00"
                        />
                        <path
                          d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z"
                          fill="#4CAF50"
                        />
                        <path
                          d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
                          fill="#1976D2"
                        />
                      </svg>
                    </div>

                    <span className="w-5/6 px-4 py-3 font-bold text-center">
                      Sign in with Google
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="flex flex-row justify-center items-center h-screen">
        <div className="max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 sm:w-11/12">
          <div className="flex flex-col px-6 py-4">
            <h2 className="text-3xl font-bold text-center text-gray-700 dark:text-white">
              Welcome back!
            </h2>

            <button
              type="button"
              className="flex items-center justify-center mt-16 mb-4 text-gray-600 rounded-lg border border-gray-200 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
              onClick={googleLoginClick}
            >
              <div className="px-4 py-3">
                <svg className="w-6 h-6" viewBox="0 0 40 40">
                  <path
                    d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
                    fill="#FFC107"
                  />
                  <path
                    d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z"
                    fill="#FF3D00"
                  />
                  <path
                    d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z"
                    fill="#4CAF50"
                  />
                  <path
                    d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
                    fill="#1976D2"
                  />
                </svg>
              </div>

              <span className="w-5/6 px-4 py-3 font-bold text-center">
                Sign in with Google
              </span>
            </button>
          </div>
          <div className="flex items-center justify-center py-4 text-center bg-gray-100 dark:bg-gray-700">
            <span className="text-sm text-gray-600 dark:text-gray-200">
              Don&apos;t have an account?{' '}
            </span>

            <a
              href="/"
              className="mx-2 text-sm font-bold text-blue-400 dark:text-blue-400 hover:text-blue-500"
            >
              Register
            </a>
          </div>
        </div>
      </div> */}
    </Page>
  );
});

export default LoginPage;
