import React, { useEffect } from "react";
import { useAppState } from "../../state";

const CommentIcon = ({ commentsCount, taskId }) => {
  const { showCommentsPanel, setShowCommentsPanel, setSelectedTask } =
    useAppState();

  return (
    <div
      className="flex items-center"
      onClick={() => {
        setShowCommentsPanel(true);
        setSelectedTask(taskId);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class={`h-6 w-6 cursor-pointer transition-all duration-100 ${
          commentsCount > 0
            ? "text-blue-400"
            : "text-gray-300 hover:text-gray-500"
        }`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
        />
      </svg>
      <div className="ml-2">{commentsCount}</div>
    </div>
  );
};

export default CommentIcon;
