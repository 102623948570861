import React from "react";
import Page from "../../components/Page/Page";
import PageContainer from "../../components/PageContainer/PageContainer";
import Navbar from "../../components/Navbar/Navbar";

const FeedbackForm = () => {
  return (
    <Page>
      <Navbar />
      <PageContainer>
        <div className="m-auto w-min">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfQQJmMfjq0kK0kJdrBlRTqv3KKWgSjoPeTcWU9mTjr6_fX2w/viewform?embedded=true"
            width="640"
            height="893"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="form"
          >
            Loading…
          </iframe>
        </div>
      </PageContainer>
    </Page>
  );
};

export default FeedbackForm;
