import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import Page from "../../components/Page/Page";
import PageContainer from "../../components/PageContainer/PageContainer";
import Navbar from "../../components/Navbar/Navbar";
import TaskDataGrid from "../../components/TaskDataGrid/TaskDataGrid";
import TaskTable from "../../components/TaskTable/TaskTable";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import NewTicketForm from "../../components/NewTicketForm/NewTicketForm";
import TestComponent from "../../components/test/TestComponent";
import { useAppState } from "../../state";
import CommentsPanel from "../../components/CommentsPanel/CommentsPanel";

const ProjectPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [taskList, setTaskList] = useState(null);
  const { id } = useParams();

  const { showCommentsPanel } = useAppState();

  const { response, loading, error } = useAxios({
    method: "get",
    url: `/projects/${id}/ticketlist`,
    headers: JSON.stringify({ accept: "*/*" }),
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    if (response !== null) {
      setData(response.data);
      console.log("TicketList: ", response.data);
    }
  }, [response]);

  return (
    <Page>
      <Navbar />
      <Sidebar display={sidebarOpen}>
        <NewTicketForm
          taskListId={data.id}
          setDisplaySidebar={setSidebarOpen}
        />
      </Sidebar>
      {showCommentsPanel && <CommentsPanel />}
      <PageContainer>
        <button
          className="bg-green-400 text-white px-4 py-2 rounded-md cursor-pointer mb-4"
          onClick={() => setSidebarOpen((curr) => !curr)}
        >
          New
        </button>
        <TaskDataGrid id={id} />
      </PageContainer>
    </Page>
  );
};

export default ProjectPage;
