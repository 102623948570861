import React from "react";
import Page from "../../components/Page/Page";
import PageContainer from "../../components/PageContainer/PageContainer";
import Navbar from "../../components/Navbar/Navbar";
import ProjectsList from "../../components/ProjectsList/ProjectsList";

const Dashboard = () => (
  <Page>
    <Navbar />
    <PageContainer>
      <ProjectsList />
    </PageContainer>
  </Page>
);

export default Dashboard;
