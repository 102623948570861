import React from "react";
import FeedbackButton from "../FeedbackButton/FeedbackButton";

const Page = ({ children }) => (
  <div className="bg-gray-100 w-screen min-h-screen relative">
    <FeedbackButton />
    {children}
  </div>
);

export default Page;
