import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import { hasPublicTag } from "../../Utils/Utils";
import ProjectCard from "../ProjectCard/ProjectCard";
import Loader from "react-loader-spinner";

const ProjectsList = () => {
  const { response, loading, error } = useAxios({
    method: "get",
    url: "/projects",
    headers: JSON.stringify({ accept: "*/*" }),
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    if (response !== null) {
      setData(response.data.filter((item) => hasPublicTag(item)));
      console.log(response.data.filter((item) => item.tags.length > 0));
    }
  }, [response]);
  return (
    <div className="flex flex-wrap w-8/12 mx-auto">
      {loading ? (
        <div className="w-screen h-screen -mt-12 flex items-center justify-center">
          <Loader type="Puff" color="#2463EB" height={70} width={70} />
        </div>
      ) : (
        data.map((proj) => {
          return <ProjectCard title={proj.name} id={proj.id} />;
        })
      )}
    </div>
  );
};

export default ProjectsList;
