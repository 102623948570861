import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import useAxios from "../../hooks/useAxios";
import "./TaskDataGrid.css";
import TableCell from "../Table/TableCell/TableCell";
import CommentIcon from "../CommentIcon/CommentIcon";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector /> */}
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function BasicFilteringGrid({ id }) {
  const { response, loading, error } = useAxios({
    method: "get",
    url: `/projects/${id}/tasks`,
    headers: JSON.stringify({ accept: "*/*" }),
  });

  const [data, setData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    console.log("Response:", response);
    if (response !== null) {
      let formattedDataArr = response.data.map((task) => {
        return {
          id: task.id,
          col1: task.content,
          col2: task.tags ? task.tags[0].name : "",
          col3: task.priority,
          col4: task.boardColumn ? task.boardColumn.name : "",
          col5: `${task["due-date"].slice(4, 6)}-${task["due-date"].slice(
            6,
            8
          )}-${task["due-date"].slice(0, 4)}`,
          comments: task["comments-count"],
          tagColour: task.tags ? task.tags[0].color : "#cecece",
          statusColour: task.boardColumn ? task.boardColumn.color : "",
        };
      });
      setFormattedData(formattedDataArr);
      setData(response.data);
    }
  }, [response]);

  const columns = [
    { field: "col1", headerName: "Name", flex: 3 },
    {
      field: "col2",
      headerName: "Tag",
      flex: 1,
      renderCell: (params) => (
        <TableCell data={params.value} textColour={params.row.tagColour} dot />
      ),
    },
    {
      field: "col3",
      headerName: "Priority",
      flex: 1,
    },
    {
      field: "col4",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        console.log(params);
        return (
          <TableCell
            data={params.value}
            textColour={params.row.statusColour}
            tag
          />
        );
      },
    },
    {
      field: "col5",
      headerName: "Est. Completion",
      flex: 1,
    },
    {
      field: "comments",
      headerName: "Comments",
      renderCell: (params) => (
        <CommentIcon commentsCount={params.value} taskId={params.id} />
      ),
      flex: 1,
    },
  ];

  //   const rows = [
  //     {
  //       id: 1,
  //       col1: "Hello",
  //       col2: "Bug",
  //       col3: "low",
  //       col4: "In progress",
  //     },
  //     {
  //       id: 2,
  //       col1: "DataGridPro",
  //       col2: "Bug",
  //       col3: "low",
  //       col4: "In progress",
  //     },
  //     {
  //       id: 3,
  //       col1: "Material-UI",
  //       col2: "Bug",
  //       col3: "medium",
  //       col4: "In progress",
  //     },
  //   ];

  return (
    <div style={{ height: 500, width: "100%" }}>
      <div
        style={{ display: "flex", height: "100%" }}
        className="bg-white p-8 rounded-lg"
      >
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            autoHeight
            rows={formattedData}
            columns={columns}
            disableSelectionOnClick
            // checkboxSelection={true}
            components={{
              Toolbar: CustomToolbar,
            }}
            // filterModel={{
            //   items: [
            //     {
            //       id: 1,
            //       columnField: "col4",
            //       operatorValue: "equals",
            //       value: "In Progress",
            //     },
            //     {
            //       id: 2,
            //       columnField: "col3",
            //       operatorValue: "equals",
            //       value: "low",
            //     },
            //   ],
            // }}
          />
        </div>
      </div>
    </div>
  );
}
