/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useAppState } from "../../state";
import useAxios from "../../hooks/useAxios";
import Comment from "../Comment/Comment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { createNewComment } from "../../Utils/Utils";

const CommentsPanel = () => {
  const schema = yup.object().shape({
    newComment: yup.string().required("Required").max(400),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    // createNewTicket(taskListId, data);
    toast.promise(createNewComment(selectedTask, data.newComment, user.email), {
      loading: "Posting comment...",
      success: () => {
        reset();
        return <b>Posted</b>;
      },
      error: (err) => `${err}`,
    });
  };

  const { setShowCommentsPanel, selectedTask, user } = useAppState();

  const { response, loading, error } = useAxios({
    method: "get",
    url: `/comments/task/${selectedTask}`,
    headers: JSON.stringify({ accept: "*/*" }),
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("Comments response:", response);
    if (response) {
      setData(response.data);
    }
  }, [response]);

  return (
    <>
      <Toaster />
      <div class="w-6/12 flex transition-all duration-200 flex-col h-screen z-10 overflow-scroll px-4 py-8 bg-white top-0 right-0 border-r shadow-xl absolute dark:bg-gray-800 dark:border-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 absolute top-8 right-8 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={() => {
            setShowCommentsPanel(false);
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <h2 className="text-2xl">Conversation</h2>
        <div className="overflow-scroll h-96">
          {data.length &&
            data.map((comment) => (
              //   <div>
              //     <p>{comment.body}</p>
              //     <p>
              //       {comment["author-firstname"]} {comment["author-lastname"]}
              //     </p>
              //     <p>{comment["datetime"]}</p>
              //   </div>
              <Comment
                author={`${comment["author-firstname"]} ${comment["author-lastname"]}`}
                datetime={comment["datetime"]}
                content={comment["html-body"]}
              />
            ))}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <input
              placeholder="New comment..."
              type="text"
              {...register("newComment")}
              className="block w-full px-2 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
            <p>{errors.newComment?.message}</p>
          </div>
          <input
            className="bg-green-400 mt-8 text-white px-4 py-2 rounded-md cursor-pointer"
            type="submit"
            value="Post"
          />
        </form>
      </div>
    </>
  );
};

export default CommentsPanel;
