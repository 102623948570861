import React from "react";
import { Link } from "react-router-dom";

const ProjectCard = ({ title, id }) => {
  return (
    <Link className="w-1/2 px-4" to={`/projects/${id}`}>
      <div className="flex bg-white rounded-lg my-4 border shadow-sm cursor-pointer">
        <div className="flex items-stretch">
          <div className="bg-blue-200 p-4 rounded-l-lg flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-7 w-7 text-blue-600 p-1 rounded-full"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
              />
            </svg>
          </div>
          <div className="ml-2 p-4">
            <p className="text-lg text-gray-800">{title}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
